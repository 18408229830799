<template>
  <img :src="iconSrc" :alt="alt" :style="iconStyle"  class="icon" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    size: {
      type: [String, Number],
      default: '30px'
    },
  },
  computed: {
    /* name传递---请按照assets的结构去传递！
    * 例：login目录下的icon   则name需传递login/icon
    * 注意大小写！
    */
    iconSrc() {
      const images = require.context('@/assets', true, /\.(png|jpg|jpeg|gif)$/);
      const imageMap = {};
      images.keys().forEach(key => {
        const imageName = key.replace('./', '').replace(/\.(png|jpg|jpeg|gif)$/, '');
        imageMap[imageName] = images(key);
      });
      return imageMap[this.name] || null;
    },
    iconStyle() {
      const sizeValue = typeof this.size === 'number' ? `${this.size}px` : this.size;
      return {
        width: sizeValue,
        height: sizeValue,
      };
    }
  }
};
</script>

<style lang="less" scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
}
</style>
