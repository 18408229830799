import Vue from 'vue'
import Antd from 'ant-design-vue'
import router from './router'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import AMap from 'vue-amap'

import 'xe-utils'
import VXETable from 'vxe-table'
import VXETablePluginAntd from 'vxe-table-plugin-antd'
import 'vxe-table/lib/style.css'
import '@/style/global.less'
import Icon from '@/components/icon/index.vue'
import store from './store';

Vue.component('Icon', Icon);
Vue.use(Antd)
Vue.use(AMap)
Vue.use(VXETable)
VXETable.use(VXETablePluginAntd)
VXETable.setup({
  table: {
    border: 'full',
  },
})

// 初始化vue-amap
AMap.initAMapApiLoader({
  // 高德key
  key: '561cd5b217914e15596740267feed954',
  // 插件集合 （插件按需引入）
  plugin: [
    'AMap.Autocomplete', // 输入提示插件
    'AMap.PlaceSearch', // POI搜索插件
    'AMap.Scale', // 右下角缩略图插件 比例尺
    'AMap.OverView', // 地图鹰眼插件
    'AMap.ToolBar', // 地图鹰眼插件
    'AMap.MapType', // 地图类型切换插件，可用于切换卫星地图
    'AMap.PolyEditor', // 多边形编辑器，绘制和编辑折现
    'AMap.CircleEditor', // 圆编辑插件
    'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
    'AMap.Geocoder' // 逆地理编码,通过经纬度获取地址所在位置详细信息
  ]
})  

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')