import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    // name: "index",
    component: () => import("../views/home/homePage"),
    children: [
      { // 首页
        path: '/',
        name: "principalSheet",
        component: () => import("../views/principal-sheet/principalSheet"),
      },
      { // 联系我们
        path: '/contact-us',
        name: 'contactUs',
        component: () => import("../views/contact-us/contactUs"),
      },
      { // 小程序
        path: '/applet',
        name: 'applet',
        component: () => import("../views/applet/index"),
      },
      { // 购买
        path: '/purchase',
        name: 'purchase',
        component: () => import("../views/order/purchase/buy.vue"),
      },
      { // 订单
        path: '/order-sheet',
        name: 'order-sheet',
        component: () => import("../views/order/order-sheet/orderSheet.vue"),
      },
      {
        path: '/loginMod',
        name: 'loginMod',
        component: () => import(/* webpackChunkName: "login" */ '../views/login'),
        children: [
          {
            path: 'login',
            name: 'login',
            component: () => import(/* webpackChunkName: "login" */ '../views/login/login'),
          },
          {
            path: 'register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register" */ '../views/login/register'),
          },
          // {
          //   path: 'reset',
          //   name: 'reset',
          //   component: () => import(/* webpackChunkName: "reset" */ '../views/login/reset'),
          // },
        ],
      },
    ]
  },
  /**
   * 小程序数据监控
   */
  { // 数据概览
    path: '/monitors',
    name: 'monitors',
    component: () => import("../views/monitor/overview"),
  },
  { // 世界地图
    path: '/world',
    name: 'world',
    component: () => import("../views/monitor/world"),
  },
  { // 漏洞扫描任务
    path: '/vs-task',
    name: 'vsTask',
    component: () => import("../views/monitor/vs-task"),
  },
  { // 漏洞扫描新建任务
    path: '/vs-task/create',
    name: 'vsTaskCreate',
    component: () => import("../views/monitor/vs-task/create"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router