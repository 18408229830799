import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    unit: '点',
    env: 'test', // 环境 test:测试  wyht:万云
    user: null, // 存储当前登录用户信息
  },
  getters: {
    user (state) { 
      return state.user
    },
    env (state) { 
      return state.env
    },
  },
  mutations: {
    setUnit(state, newUnit) {
      state.unit = newUnit;
    },
    setUser(state, {userData}) {
      state.user = userData;
    },
    clearUser(state) {
      state.user = null;
    },
  }
});

export default store;
