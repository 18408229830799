<template>
  <div id="app">
    <!-- Preloader -->
    <div class="preloader"></div>
    <!-- Preloader -->
    <!-- 万云互通 -->
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  provide() {
    return {
      reload : this.reload,
    }
  },
  methods: {
    reload(){
      this.$router.go(0);
    },
  },
}
</script>

<style>
#app {
}
</style>
